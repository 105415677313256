function initShoppingCart() {
  const body = document.querySelector('body');

  function bindEvents() {
    const blockCart = document.querySelector('.js-blockcart');

    // blockCart.addEventListener('show.bs.dropdown', () => { Change to vanilla js when bootstrap 5 is adopted
    $(blockCart).on('show.bs.dropdown', () => {
      body.classList.add('header-dropdown-open', 'block-cart-open');
    });

    // blockCart.addEventListener('hide.bs.dropdown', (e) => { Change to vanilla js when bootstrap 5 is adopted
    $(blockCart).on('hide.bs.dropdown', (e) => {
      const { target } = e;
      if (!target.classList.contains('dropdown-close')
          && (target.classList.contains('keep-open') || target.closest('.keep-open')
          || (e.clickEvent && e.clickEvent.target.closest('.keep-open')))) {
        return false; // returning false should stop the dropdown from hiding.
      }
      body.classList.remove('header-dropdown-open', 'block-cart-open');
      return true;
    });
  }

  prestashop.blockcart = prestashop.blockcart || {};

  const { showModal } = prestashop.blockcart;

  bindEvents();

  prestashop.on(
    'updateCart',
    (event) => {
      const refreshURL = document.querySelector('.js-blockcart').dataset.refreshUrl;
      let requestData = {
        ajax: 1,
      };

      if (event && event.reason && typeof event.resp !== 'undefined' && !event.resp.hasError) {
        requestData = {
          id_customization: event.reason.idCustomization,
          id_product_attribute: event.reason.idProductAttribute,
          id_product: event.reason.idProduct,
          action: event.reason.linkAction,
          ajax: 1,
        };
      }

      requestData = Object.keys(requestData).map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(requestData[key])}`).join('&');

      if (event && event.resp && event.resp.hasError) {
        // const errorModal = document.querySelector('#blockcart-error');
        // const alertBlock = document.querySelector('.js-blockcart-alert');

        // alertBlock.innerHTML = event.resp.errors.join('<br/>');
        // errorModal.modal('show'); Change to vanilla js when bootstrap 5 is adopted
        // $(errorModal).modal('show');

        prestashop.emit('handleError', {
          resp: event.resp,
        });
      }

      fetch(refreshURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: requestData,
      })
        .then((resp) => resp.json())
        .then((resp) => {
          const previewHtml = new DOMParser().parseFromString(resp.preview, 'text/html').querySelector('.js-blockcart');

          if (previewHtml) {
            document.querySelector('.js-blockcart').replaceWith(previewHtml);
          }

          if (resp.modal) {
            showModal(resp.modal);
          }

          prestashop.emit('updatedBlockCart', resp);

          if (body.classList.contains('block-cart-open')) {
            const dropdown = body.querySelector('.js-blockcart [data-toggle="dropdown"]');

            if (dropdown) {
              dropdown.click();
            }
          }

          bindEvents();

          body.classList.remove('cart-loading');
        })
        .catch((resp) => {
          prestashop.emit('handleError', { eventType: 'updateShoppingCart', resp });
        });
    },
  );

  prestashop.on(
    'handleError',
    (response) => {

      let resp = response.resp || {};

      if (resp.responseJSON) {
        resp = resp.responseJSON;
      }

      if (resp.modal) {
        showModal(resp.modal);
      } else {

        let titleMessage = prestashop.modules.mrb_shoppingcart.default_title_message_error || 'Oops! An unexpected error has occurred';

        if (typeof resp.titleMessage !== 'undefined') {
          titleMessage = resp.titleMessage;
        }
        
        let bodyMessage = resp.bodyMessage || prestashop.modules.mrb_shoppingcart.default_body_message_error || 'We\'re sorry, but it seems that a grain of sand has slipped into the gears of our website. Could you please try again later?';
        let closeMessageButton = resp.closeMessageButton || prestashop.modules.mrb_shoppingcart.default_close_message_button_error || 'Close';

        if (resp.errors) {
          bodyMessage = resp.errors.join('<br/>');
        }

        // create a default error modal html and show it (with showModal)
        const modalHtml = `
          <div class="modal fade" id="blockcart-modal" tabindex="-1" role="dialog" aria-labelledby="blockcart-modal-label" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">` + 
              ((titleMessage) ?
                `<div class="modal-header">
                  <h5 class="modal-title" id="blockcart-modal-label">` + titleMessage + `</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>` : ``) +
                `<div class="modal-body">` + bodyMessage + `</div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">` + closeMessageButton + `</button>
                </div>
              </div>
            </div>
          </div>
        `;

        showModal(modalHtml);
      }
    },
  );

}

document.addEventListener('DOMContentLoaded', () => {
  initShoppingCart();
});